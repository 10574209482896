@use "src/styles/typography";
@use "src/styles/layout";
@use "src/styles/palette";

.overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cta {
  display: inline-block;

  @media (max-width: layout.breakpoint(md)) {
    order: 3;
  }
}

.items {
  @include layout.grid();

  width: 100%;
  padding: 0;
  margin: 1.125rem -0.5rem;
  row-gap: 2rem;
}

.item {
  @include layout.column($md: 4);
}

.item_title {
  @extend .header_5;

  margin: 0.25rem 0;
}

.item_publication_data {
  @extend .caption_text;

  color: palette.grey(dark);
}
